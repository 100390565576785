import React, {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useEffect,
} from 'react';
import { isSafari } from 'react-device-detect';

import COLORS from '~/constants/colors';
import { FONT_SIZES, FONTS } from '~/constants/typography';
import { stopEventEffects } from '~/utils';

import { Letter, Sentence, Letters, Sounds } from '../../../../models';
import { insertCaret } from '../../helpers';
import { cleanCarets } from '../../../../helpers';
import Stamp from './components/Stamp';
import Notice from './components/Notice';
import Autocomplete from './components/Autocomplete';

const KEY_CODES = {
  enter: 'Enter',
  backspace: 'Backspace',
  escape: 'Escape',
};

interface InputBoxProps {
  index: number;
  patterns: string[];
  setPatterns: Dispatch<SetStateAction<string[]>>;
  sentence?: Sentence;
  sounds?: Sounds;
  disabled?: boolean;
  addLine: () => void;
  removeLine: () => void;
}

function InputBox({
  index,
  patterns,
  setPatterns,
  sentence = [],
  sounds = [],
  disabled,
  addLine,
  removeLine,
}: InputBoxProps) {
  const [wrong, setWrong] = useState<Letter>();
  const [chunk, setChunk] = useState<string>();
  const inputBoxRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const placeholderRef = useRef<HTMLSpanElement>(null);
  const isEmpty = (textAreaRef.current?.value.length ?? 0) === 0;
  const placeholder = disabled ? 'Please wait...' : 'Enter a pattern';
  const cols = 41;
  const maxLength = cols * 21;
  const getStampKey = (idx) => `PagesBeatbloxScriptInputBoxStamp${idx}`;

  // Display
  const letters = sentence.reduce(
    (acc, w) => [
      ...acc,
      ...cleanCarets(w.value)
        .split('')
        .map((c, cIdx) => ({ ...w, char: c, cIdx })),
    ],
    [] as Letters
  );

  // Helpers
  const getRows = (v) => Math.ceil(v.length / (cols + 1)) || 1;
  const adjustDisplay = (rows) => {
    if (inputBoxRef.current && textAreaRef.current && spanRef.current) {
      const fat = rows * (isSafari ? 2 : 2.25);

      inputBoxRef.current.style.height = `calc(1em + ${rows}em + ${fat}px)`;
      textAreaRef.current.rows = rows;
      spanRef.current.style.height = `calc(${rows}em + ${fat}px)`;
    }
  };
  const processInput = (value, caretPos) => {
    const rows = getRows(value);
    const newPatterns = [...patterns];

    adjustDisplay(rows);
    newPatterns[index] = insertCaret(value, caretPos);
    setPatterns(newPatterns);
    if (textAreaRef.current) textAreaRef.current.value = value; // Seems redundant but needed for caret position when pasting
  };
  const replaceChunk = (notation) => {
    if (textAreaRef.current) {
      const safeNotation = notation.length > 1 ? `[${notation}]` : notation;
      const selStart = textAreaRef.current.selectionStart - 1;
      const wStart = selStart - letters[selStart].cIdx;
      const wEnd = wStart + cleanCarets(letters[selStart].value).length;
      const substr1 = textAreaRef.current.value.substring(0, wStart);
      const substr2 = textAreaRef.current.value.substring(wEnd);
      const finalPos = wStart + safeNotation.length;

      processInput(`${substr1}${safeNotation}${substr2}`, finalPos);
      textAreaRef.current.selectionStart = finalPos;
      textAreaRef.current.selectionEnd = finalPos;
      setWrong(undefined);
    }
  };

  // Handlers
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const paste = clipboardData.getData('text');

    if (textAreaRef.current) {
      const cleaned = paste.replace(/[\t\n\r]/gm, ' ');
      const selStart = textAreaRef.current.selectionStart;
      const selEnd = textAreaRef.current.selectionEnd;
      const substr1 = textAreaRef.current.value.substring(0, selStart);
      const substr2 = textAreaRef.current.value.substring(selEnd);
      const finalStr = `${substr1}${cleaned}${substr2}`.substring(0, maxLength);
      const finalPos = selStart + cleaned.length;

      stopEventEffects(event);
      processInput(finalStr, finalPos);
      textAreaRef.current.selectionStart = finalPos;
      textAreaRef.current.selectionEnd = finalPos;
    }
  };
  const handleKeyDown = (event) => {
    if (!disabled && !event.repeat) {
      if (event.code === KEY_CODES.enter) {
        stopEventEffects(event);
        addLine();
      } else if (
        event.code === KEY_CODES.backspace &&
        !event.target.value &&
        patterns.length > 1
      ) {
        stopEventEffects(event);
        removeLine();
      }
    }
  };
  const handleInput = (event) =>
    processInput(event.target.value, textAreaRef.current?.selectionStart ?? 0);
  const handleFocus = (event) => {
    stopEventEffects(event);
    if (inputBoxRef.current)
      inputBoxRef.current.style.borderColor = COLORS.doveGray;
    if (placeholderRef.current)
      placeholderRef.current.style.color = COLORS.gallery;
  };
  const handleBlur = (event) => {
    stopEventEffects(event);
    setWrong(undefined);
    setChunk(undefined);
    if (inputBoxRef.current)
      inputBoxRef.current.style.borderColor = COLORS.platinum;
    if (placeholderRef.current)
      placeholderRef.current.style.color = COLORS.silver;
  };
  const handleMouseDown = (event) => {
    if (disabled) stopEventEffects(event);
  };
  const handleSupport = (event) => {
    if (event.code === KEY_CODES.escape) {
      stopEventEffects(event);
      setWrong(undefined);
      setChunk(undefined);
    } else if (textAreaRef.current) {
      const selStart = textAreaRef.current.selectionStart;
      const selEnd = textAreaRef.current.selectionEnd;

      // Notice
      if (selStart === selEnd && letters[selStart]?.error)
        setWrong(letters[selStart]);
      else setWrong(undefined);

      // Autocomplete
      if (selStart === selEnd && letters[selStart - 1])
        setChunk(letters[selStart - 1].value);
      else setChunk(undefined);
    }
  };

  useEffect(() => {
    const rows = getRows(cleanCarets(patterns[index]));

    if (rows !== textAreaRef.current?.rows) adjustDisplay(rows);
  }, [patterns]);

  // Styling
  const inputBoxStyle = {
    display: 'inline-block',
    position: 'relative' as const,
    width: `calc(${cols}ch + 2em)`,
    height: 'calc(2em + 2px)',
    fontSize: FONT_SIZES.text,
    fontFamily: FONTS.typewriter,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '0.4em',
    borderColor: COLORS.platinum,
    textAlign: 'left' as const,
    verticalAlign: 'middle',
  };
  const baseStyle = {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    margin: 0,
    padding: '0.5em',
    border: 0,
    fontSize: 'inherit',
    fontFamily: 'inherit',
    overflow: 'hidden',
    whiteSpace: 'break-spaces' as const,
    wordWrap: 'break-word' as const,
    wordBreak: 'break-all' as const,
    lineBreak: 'anywhere' as const,
  };
  const textAreaStyle = {
    ...baseStyle,
    width: `${cols + 1}ch`,
    lineHeight: '1.15em',
    color: COLORS.transparent,
    backgroundColor: COLORS.transparent,
    caretColor: COLORS.black,
    outline: 'none',
    resize: 'none' as const,
    cursor: disabled ? 'default' : 'text',
    zIndex: 1,
  };
  const spanStyle = {
    ...baseStyle,
    width: `calc(${cols}ch + 1em)`,
    zIndex: 0,
  };
  const emptyStyle = {
    ...baseStyle,
    color: COLORS.silver,
    zIndex: -1,
  };

  return (
    <div
      role="none"
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      style={inputBoxStyle}
      ref={inputBoxRef}
    >
      <Notice wrong={wrong} />
      <Autocomplete sounds={sounds} chunk={chunk} replaceChunk={replaceChunk} />
      <textarea
        rows={1}
        value={cleanCarets(patterns[index])}
        maxLength={maxLength}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseUp={handleSupport}
        onTouchEnd={handleSupport}
        onKeyUp={handleSupport}
        disabled={disabled}
        autoComplete="off"
        autoCorrect="off"
        spellCheck={false}
        style={textAreaStyle}
        ref={textAreaRef}
      />
      <span aria-hidden style={spanStyle} ref={spanRef}>
        {letters.map((l, idx) => (
          <Stamp key={getStampKey(idx)} letter={l} />
        ))}
      </span>
      {isEmpty && (
        <span style={emptyStyle} ref={placeholderRef}>
          {placeholder}
        </span>
      )}
    </div>
  );
}

export default InputBox;
