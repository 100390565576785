import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import COLORS from '~/constants/colors';
import { FONT_WEIGHTS } from '~/constants/typography';
import { stopEventEffects } from '~/utils';

import ARROWS from '../constants/arrows';
import { LABELS } from '../constants/kana';
import PHASES from '../constants/phases';

interface InputPanelMeas {
  width: string;
  height: string;
  border: string;
  margin: string;
  space: string;
}

const DEFAULT_INPUT_PANEL_MEAS: InputPanelMeas = {
  width: 'min(16.7vmin, 75px)',
  height: 'min(16.7vmin, 75px)',
  border: 'min(0.67vmin, 3px)',
  margin: 'min(0.67vmin, 3px)',
  space: 'min(2.68vmin, 12px)', // From 2 margins plus 2 borders
};

interface InputPanelProps {
  labels: string[];
  centerKeyCode: string;
  callback: (string) => void;
  meas?: InputPanelMeas;
  showRingBorder?: boolean;
  showAllHints?: boolean;
  answerIdx?: number;
  phase?: string;
  ringDelay?: number;
}

function InputPanel({
  labels,
  centerKeyCode,
  callback,
  meas = DEFAULT_INPUT_PANEL_MEAS,
  showRingBorder = false,
  showAllHints = false,
  answerIdx,
  phase,
  ringDelay = isMobile ? 420 : 260,
}: InputPanelProps): ReactElement {
  const [ringDisplay, setRingDisplay] = useState('none');
  const [ringOpacity, setRingOpacity] = useState(0);
  const [activeIdx, setActiveIdx] = useState(-1);
  const [counter, setCounter] = useState(0);
  const [centerKeyDown, setCenterKeyDown] = useState(false);
  const [protectPress, setProtectPress] = useState(true);
  let startTime;
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const getKey = (idx) => `PagesFlowGoInputPanelRing${idx}`;

  // Timer controls
  const startCounter = () => {
    if (!intervalRef.current) {
      startTime = Date.now();
      intervalRef.current = setInterval(() => {
        setCounter(() => Date.now() - startTime);
      }, 10);
    }
  };
  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setCounter(0);
    }
  };

  // Mouse interactions
  const stopExtraEffects = (event) => event.preventDefault();
  const fullClear = () => {
    stopCounter();
    setActiveIdx(-1);
    setProtectPress(true);
    setRingOpacity(0);
    setRingDisplay('none');
  };
  const handleCenterUp = () => {
    if (!protectPress) callback(labels[0]);
    fullClear();
  };
  const handleCenterDown = () => {
    setRingDisplay('block');
    setProtectPress(false);
    startCounter();
  };
  const handleCenterHold = () => {
    if (counter > ringDelay) {
      setRingOpacity(1);
      stopCounter();
    }
  };
  const handleRingMouseEnter = (labelIdx) => () => {
    if (labelIdx < labels.length && !protectPress) {
      setActiveIdx(labelIdx);
      callback(labels[labelIdx]);
    }
    fullClear(); // Comment if you want center hold to persist between notes
  };
  const handleCenterMouseLeave = () => {
    if (labels.length === 1) fullClear(); // Special case for modifier character
  };

  // Touch interactions
  const getActiveIdx = (event) => {
    const changedTouch = event.changedTouches[0];
    const bounds = changedTouch.target.getBoundingClientRect();
    const x = changedTouch.pageX;
    const y = changedTouch.pageY;
    const r = {
      l: bounds.left + window.pageXOffset,
      t: bounds.top + window.pageYOffset,
      r: bounds.right + window.pageXOffset,
      b: bounds.bottom + window.pageYOffset,
    };
    const endElement = document.elementFromPoint(x, y);
    const inCenter = x >= r.l && y >= r.t && x <= r.r && y <= r.b;

    if (changedTouch.target === endElement || inCenter) return 0;
    else if (x < r.l && y >= r.t - r.l + x && y <= r.b + r.l - x) return 1;
    else if (y < r.t && x > r.l - r.t + y && x <= r.r + r.t - y) return 2;
    else if (x > r.r && y > r.t + r.r - x && y <= r.b - r.r + x) return 3;

    return 4;
  };
  const handleTouchEnd = (event) => {
    const labelIdx = getActiveIdx(event);

    stopEventEffects(event); // Needed to prevent double trigger on mobile
    if (labelIdx < labels.length && !protectPress) callback(labels[labelIdx]);
    fullClear();
  };
  const handleTouchMove = (event) => {
    const labelIdx = getActiveIdx(event);

    stopEventEffects(event);
    if (labelIdx < labels.length && !protectPress && labelIdx !== activeIdx)
      setActiveIdx(labelIdx);
  };

  // Key interactions
  const handleKeyDown = (event) => {
    if (!event.repeat) {
      if (event.code === centerKeyCode && !centerKeyDown) {
        setCenterKeyDown(true);
        handleCenterDown();
      } else if (Object.keys(ARROWS).includes(event.code) && centerKeyDown) {
        if (!protectPress) stopEventEffects(event);
        handleRingMouseEnter(ARROWS[event.code])();
        setCenterKeyDown(false); // Comment if you want center hold to persist between notes
      }
    }
  };
  const handleKeyUp = (event) => {
    if (event.code === centerKeyCode && centerKeyDown) {
      handleCenterUp();
      setCenterKeyDown(false);
    }
  };

  // Styling
  const inputPanelStyle = {
    float: 'left' as const,
    position: 'relative' as const,
    width: `calc(${meas.width} + 2 * ${meas.border})`,
    height: `calc(${meas.height} + 2 * ${meas.border})`,
    margin: meas.margin,
    verticalAlign: 'top',
    cursor: 'pointer',
  };
  const centerStyle: Record<string, any> = {
    width: meas.width,
    height: meas.height,
    lineHeight: meas.height,
    textAlign: 'center' as const,
    borderStyle: 'solid',
    borderColor: COLORS.white,
    borderWidth: meas.border,
    borderRadius: `calc(4 * ${meas.border})`,
    color: COLORS.white,
    backgroundColor: !protectPress ? COLORS.black75 : COLORS.black50,
    boxShadow: `0 0 0.35em 0 ${COLORS.black25}`,
    overflow: 'hidden',
  };
  const noBorderStyle = {
    borderStyle: 'none',
    borderRadius: `calc(3 * ${meas.border})`,
    boxShadow: 'none',
  };
  const ringStyle = (idx) => {
    const isActive = idx === activeIdx;

    return {
      ...centerStyle,
      display: ringDisplay,
      position: 'absolute' as const,
      color: COLORS.black,
      backgroundColor: isActive ? COLORS.jaffa : COLORS.kournikova,
      opacity: isActive ? 1 : ringOpacity,
      zIndex: ringDisplay === 'none' ? ('auto' as const) : 11,
      ...(!showRingBorder && noBorderStyle),
    };
  };
  const borderOffset = (bs) => `${meas.margin} + ${bs} * ${meas.border}`;
  const ringOffsets = {
    first: `calc(100% + ${borderOffset(showRingBorder ? 1 : 2)})`,
    second: showRingBorder ? 0 : meas.border,
  };
  const ringStyles = [
    {
      ...ringStyle(1),
      right: ringOffsets.first,
      top: ringOffsets.second,
    },
    {
      ...ringStyle(2),
      bottom: ringOffsets.first,
      left: ringOffsets.second,
    },
    {
      ...ringStyle(3),
      left: ringOffsets.first,
      top: ringOffsets.second,
    },
    {
      ...ringStyle(4),
      top: ringOffsets.first,
      left: ringOffsets.second,
    },
  ];
  const labelStyle = {
    display: 'inline-block',
    lineHeight: 'normal',
    verticalAlign: 'middle',
    fontSize: `calc((${meas.height} * 3) / 7)`,
    fontWeight: FONT_WEIGHTS.medium,
  };
  const romajiStyle = {
    margin: 0,
    fontSize: `calc((${meas.height} * 2) / 9)`,
  };

  // Set guidance visual cue
  if (answerIdx !== undefined) {
    const getOffset = (m) =>
      `calc(${0.15 * m} * min(${meas.width}, ${meas.height}))`;
    const guideColor = COLORS.mandy;

    if (answerIdx === 0)
      centerStyle.boxShadow = `inset 0 0 0 ${getOffset(1)} ${guideColor}`;
    else {
      const indicators = [(2 - answerIdx) % 2, (3 - answerIdx) % 2];
      const offsets = indicators.map((i) => getOffset(i));

      centerStyle.boxShadow = `inset ${offsets[0]} ${offsets[1]} 0 0 ${guideColor}`;
      ringStyles[answerIdx - 1].backgroundColor = COLORS.cinderella;
    }
  }

  useEffect(() => handleCenterHold, [counter]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  useEffect(() => {
    if (phase === PHASES.play) fullClear();
  }, [phase]);

  return (
    <div role="none" onMouseDown={stopExtraEffects} style={inputPanelStyle}>
      <div
        role="none"
        onMouseUp={handleCenterUp}
        onMouseDown={handleCenterDown}
        onMouseEnter={stopCounter}
        onMouseLeave={handleCenterMouseLeave}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleCenterDown}
        style={centerStyle}
      >
        <div style={labelStyle}>
          {labels[0]}
          {(showAllHints ||
            (answerIdx !== undefined && LABELS.romaji[labels[0]])) && (
            <p style={romajiStyle}>{LABELS.romaji[labels[0]]}</p>
          )}
        </div>
      </div>
      {labels.slice(1).map((label, idx) => (
        <div
          key={getKey(idx)}
          role="none"
          onMouseEnter={handleRingMouseEnter(idx + 1)}
          style={ringStyles[idx]}
        >
          <div style={labelStyle}>
            {label}
            {(showAllHints ||
              (answerIdx !== undefined && LABELS.romaji[label])) && (
              <p style={romajiStyle}>{LABELS.romaji[label]}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default InputPanel;
export { DEFAULT_INPUT_PANEL_MEAS };
export type { InputPanelMeas };
