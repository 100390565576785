import React, { ReactElement } from 'react';

import COLORS from '~/constants/colors';
import Heart from '~/components/shapes/Heart';
import ProgressBar from '~/components/ProgressBar';

import { RendererProps } from './models';
import { MetricsPredicate } from '../predicates/models';
import { INITIAL_HEALTH } from '../constants/mechanics';

function Metrics({ predicate }: RendererProps<MetricsPredicate>): ReactElement {
  const pos = predicate.getPos();
  const heartContainers = [...Array(INITIAL_HEALTH).keys()];
  const getKey = (idx) => `PagesFlowGoMetricsHearts${idx}`;

  // Styling
  const getPx = (v) => `${v}px`;
  const maxHeartW = Math.floor(predicate.dims.w / (INITIAL_HEALTH + 1));
  const maxHeartH = predicate.dims.h / 2;
  const heartB = (predicate.dims.b ?? 0) / 2.5;
  const heartM = predicate.dims.m ?? 0;
  const heartW = Math.min(maxHeartW, maxHeartH) - heartM;
  const metricsW = 10 * (heartW + heartM);
  const metricsH = 2 * heartW + 3 * heartM;
  const metricsStyle = {
    position: 'absolute' as const,
    width: getPx(metricsW),
    height: getPx(metricsH),
    top: getPx(pos.y - metricsH - heartM),
    left: getPx(pos.x + heartM),
    ...predicate.style,
  };

  // Styling
  const healthStyle = {
    display: 'flex',
    marginBottom: getPx(heartM),
    textAlign: 'left' as const,
    backgroundColor: COLORS.pickledBluewood,
    borderColor: COLORS.pickledBluewood15,
    borderRadius: getPx(2.5 * heartB),
    borderStyle: 'solid',
    borderWidth: `0 ${getPx(heartB)}`,
  };
  const heartStyle = {
    margin: getPx(heartM / 2),
  };
  const liveColor = (idx) =>
    predicate.special && idx === 0 ? COLORS.black : COLORS.mandy;

  // Styling
  const scoreHeight = getPx(heartW + heartM - 2);
  const scoreStyle = {
    width: getPx(metricsW),
    height: scoreHeight,
  };
  const containerStyle = {
    backgroundColor: COLORS.black50,
    borderColor: COLORS.white25,
    borderStyle: 'solid',
    borderWidth: getPx(1),
  };
  const loadedStyle = {
    backgroundColor: COLORS.dodgerBlue,
    borderStyle: 'none',
  };
  const pointsStyle = {
    color: COLORS.cornflower,
    fontSize: getPx(heartW / 1.5),
  };

  return (
    <div style={metricsStyle}>
      <div style={healthStyle}>
        {heartContainers.map((idx) => (
          <Heart
            key={getKey(idx)}
            width={getPx(heartW)}
            fill={predicate.hearts > idx ? liveColor(idx) : COLORS.alto}
            style={heartStyle}
          />
        ))}
      </div>
      <div style={scoreStyle}>
        <ProgressBar
          points={predicate.points}
          total={predicate.total}
          width="100%"
          height={scoreHeight}
          containerStyle={containerStyle}
          loadedStyle={loadedStyle}
          pointsStyle={pointsStyle}
        />
      </div>
    </div>
  );
}

export default Metrics;
