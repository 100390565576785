import React, { ReactElement } from 'react';

import { Beat, Chart, Charts } from '../../models';
import { State } from './models';
import { getMaxTiming } from '../../helpers';
import { TEMPO_NUMERATOR } from '../../constants/defaults';
import Monitor from './components/Monitor';
import { MARKS } from '../../constants/language';

interface VisualizeProps {
  beat: Beat;
  charts: Charts;
  state?: State;
  progress?: number;
}

function Visualize({
  beat,
  charts,
  state,
  progress = 0,
}: VisualizeProps): ReactElement {
  let groups = Object.keys(charts).map((g) => Number(g));
  const mpb = TEMPO_NUMERATOR / Math.max(beat?.bpm, 1);
  const carets = Object.values(charts).reduce((acc, c) => {
    const cs = c.filter((n) => n.sound.notation === MARKS.caret);

    return cs.length > 1 ? cs : acc;
  }, [] as Chart);
  const minTiming = carets.length > 1 ? carets[0].start : 0;
  const maxTiming = Object.values(charts).reduce(
    (acc, c) => Math.max(acc, getMaxTiming(c)),
    0
  );
  const count = Math.ceil(maxTiming / mpb + 1);
  const getKey = (idx) => `PagesBeatbloxVisualizeMonitor${idx}`;

  if (groups.length === 0) groups = [0];

  return (
    <>
      {groups.map((g, idx) => (
        <Monitor
          key={getKey(idx)}
          chart={charts[g]}
          mpb={mpb}
          minTiming={minTiming}
          maxTiming={maxTiming}
          count={count}
          state={state}
          progress={progress}
          group={g}
        />
      ))}
    </>
  );
}

export default Visualize;
