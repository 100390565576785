import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import FramedView from '~/components/FramedView';
import Button from '~/components/buttons/Button';
import VARIANTS from '~/constants/variants';
import Divider from '~/components/flow/Divider';
import Code from '~/components/flow/Code';
import Link from '~/components/flow/Link';
import { stopEventEffects } from '~/utils';
import urls from '~/urls';
import Title from '~/components/titles/Title';
import Header from '~/components/titles/Header';

import { PlaySettings, PlayResults } from '../models';
import PHASES from '../constants/phases';
import {
  POINTS,
  SPECIAL_SCORE_THRESHOLD,
  MIN_SPECIAL_POSSIBLE_POINTS,
} from '../constants/mechanics';
import { NOTE_KEYS, THRESHOLD_COLORS } from '../constants/layout';
import { INDEX_MAP } from '../constants/kana';

interface EndingProps {
  phase: string;
  setPhase: Dispatch<SetStateAction<string>>;
  results: PlayResults;
  settings: PlaySettings;
}

function Ending({
  phase,
  setPhase,
  results,
  settings,
}: EndingProps): ReactElement {
  const handlePlayAgain = (event) => {
    stopEventEffects(event);
    setPhase(PHASES.menu);
  };
  const studyList: Record<string, { combo: string; link: string | null }> = {};
  const needToStudy = (r) => r === NOTE_KEYS.miss || r === NOTE_KEYS.wrong;
  const getKey = (type, idx) => `PagesFlowGoEnding${type}Option${idx}`;
  const checkInclusion = (answers) =>
    answers.every((answer) => Object.keys(INDEX_MAP).includes(answer));

  if (results.miss > 0 || results.wrong > 0)
    results.attempts.forEach((attempt) => {
      if (!studyList[attempt.problem.prompt] && needToStudy(attempt.rating))
        studyList[attempt.problem.prompt] = {
          combo: attempt.problem.answers.join(''),
          link: checkInclusion(attempt.problem.answers)
            ? `https://jisho.org/search/${attempt.problem.prompt}`
            : null,
        };
    });

  // Calculations
  const needToStudyLast = needToStudy(results.attempts.slice(-1)[0]?.rating);
  const chip = needToStudyLast && results.health <= 0 ? 1 : 0;
  const tries = results.attempts.length;
  const completion = (tries - chip) / settings.rhythm.timings.length;
  const avgError = results.delta / results.hits;
  const avgSquaredError = results.deltaSquared / results.hits;
  const mean = Math.abs(Math.round(avgError));
  const stdDev = Math.round(Math.sqrt(avgSquaredError - avgError ** 2));
  const correctness =
    tries > 0 ? (tries - results.wrong - results.miss) / tries : 0;
  const studyPrompts = Object.keys(studyList);
  const studyResources = Object.values(studyList);
  const possiblePoints = settings.rhythm.timings.length * POINTS.perfect;

  // Interface relevant
  let condition: VARIANTS | string = VARIANTS.fail;
  const getPRLetter = (letter, color) => (
    <span style={{ color }}>{letter}</span>
  );
  const subheaders = {
    [VARIANTS.success]:
      results.points === possiblePoints ? (
        <>
          {getPRLetter('P', COLORS.mandy)}
          {getPRLetter('e', COLORS.jaffa)}
          {getPRLetter('r', COLORS.kournikova)}
          {getPRLetter('f', COLORS.wildWillow)}
          {getPRLetter('e', COLORS.malibu)}
          {getPRLetter('c', COLORS.lilacBush)}
          {getPRLetter('t', COLORS.mandy)} {getPRLetter('R', COLORS.jaffa)}
          {getPRLetter('u', COLORS.kournikova)}
          {getPRLetter('n', COLORS.wildWillow)}
        </>
      ) : (
        'Perfect Run'
      ),
    [VARIANTS.warning]: 'Completion',
    [VARIANTS.fail]: 'You Lose...',
    [PHASES.endingExit]: 'Incomplete Exit',
  };
  const blurbs = {
    [VARIANTS.success]: 'Hope the EXP helped you level up! 🙏',
    [VARIANTS.warning]: "The last bit's the hardest but keep fighting! 💪",
    [VARIANTS.fail]: "Keep up the grind, you're doing great! ❤️‍🔥",
    guidance:
      "Think you'll be comfortable taking off the training wheels next time?",
  };

  if (phase === PHASES.endingExit) condition = PHASES.endingExit;
  else if (results.damage === 0) condition = VARIANTS.success;
  else if (results.health > 0) condition = VARIANTS.warning;

  // Handle special
  const cumSpecial = results.attempts.reduce(
    (acc, a) => acc || a.special,
    false
  );
  const getSpecialBlurb = () => {
    if (results.gotSpecial) {
      if (
        results.points / possiblePoints > SPECIAL_SCORE_THRESHOLD &&
        possiblePoints >= MIN_SPECIAL_POSSIBLE_POINTS
      )
        return (
          <span>
            {' '}
            You got <b>it</b>! Share a screenshot of these results on{' '}
            <Link href={urls.discordInvite}>Discord</Link> in{' '}
            <Code>#japanese</Code> for woot.
          </span>
        );

      return (
        <span>
          {' '}
          You caught <b>it</b>? Congrats, but try scoring well too.
        </span>
      );
    } else if (cumSpecial)
      return (
        <span>
          {' '}
          Too bad you missed <b>it</b>.
        </span>
      );

    return null;
  };

  // Styling
  const endingStyle = {
    width: 'min(45em, 82.5vw)',
  };
  const frameColors: [string, string] = [
    COLORS.waikawaGray,
    COLORS.waikawaGray,
  ];
  const buttonStyle = {
    width: '9em',
    margin: '0 0.25em 0.38em',
    boxShadow: `-0.1em 0.1em ${COLORS.black25}`,
    borderStyle: 'none',
    fontSize: FONT_SIZES.text,
  };
  const levelStyle = {
    display: 'block',
    marginTop: '1em',
  };
  const levelUlStyle = {
    ...levelStyle,
    paddingLeft: '1.5em',
    margin: '0.75em 0 0',
  };
  const levelLiStyle = {
    lineHeight: '1.75em',
  };
  const getStatStyle = (color = COLORS.dodgerBlue) => ({
    color,
  });
  const statDividerStyle = {
    margin: '0 0.25em',
  };
  const timingColor = COLORS.dodgerBlue;
  const correctnessColor = COLORS.dodgerBlue;
  const healthColor =
    results.gotSpecial && results.health === 1 ? COLORS.black : COLORS.mandy;

  return (
    <FramedView accent colors={frameColors} style={endingStyle}>
      <Title>{subheaders[condition]}</Title>
      <br />
      You survived <b style={getStatStyle()}>
        {Math.floor(completion * 100)}%
      </b>{' '}
      of the rhythm. {blurbs[condition]} {settings.guidance && blurbs.guidance}
      {getSpecialBlurb()}
      <ul style={levelUlStyle}>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle()}>{results.points}</b>
            <span style={statDividerStyle}>/</span>
            <b style={getStatStyle()}>{possiblePoints}</b>
          </Code>{' '}
          points scored
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(COLORS.lilacBush)}>{results.damage}</b>
          </Code>{' '}
          damage taken
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(healthColor)}>
              {Math.max(results.health, 0)}
            </b>
          </Code>{' '}
          hearts remaining
        </li>
      </ul>
      <Divider />
      <Header>Track Timing</Header>
      {results.hits > 0 && (
        <>
          You were <b style={getStatStyle(timingColor)}>{mean}</b> milliseconds{' '}
          <b style={getStatStyle(timingColor)}>
            {results.delta > 0 ? 'late' : 'early'}
          </b>{' '}
          on average with <b style={getStatStyle(timingColor)}>{stdDev}</b>{' '}
          milliseconds deviation.
        </>
      )}
      <ul style={levelUlStyle}>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(THRESHOLD_COLORS.perfect)}>
              {results.perfect}
            </b>
          </Code>{' '}
          perfects
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(THRESHOLD_COLORS.good)}>{results.good}</b>
          </Code>{' '}
          goods
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(THRESHOLD_COLORS.okay)}>{results.okay}</b>
          </Code>{' '}
          okays
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(THRESHOLD_COLORS.bad)}>{results.bad}</b>
          </Code>{' '}
          bads
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(THRESHOLD_COLORS.awful)}>{results.awful}</b>
          </Code>{' '}
          awfuls
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(THRESHOLD_COLORS.pre)}>{results.pre}</b>
          </Code>{' '}
          befores
        </li>
        <li style={levelLiStyle}>
          <Code>
            <b style={getStatStyle(THRESHOLD_COLORS.miss)}>{results.miss}</b>
          </Code>{' '}
          misses
        </li>
      </ul>
      <Divider />
      <Header>Check Correctness</Header>
      You got{' '}
      <b style={getStatStyle(correctnessColor)}>
        {Math.round(correctness * 100)}%
      </b>{' '}
      right in <b style={getStatStyle(correctnessColor)}>{tries}</b> chances
      with <b style={getStatStyle(correctnessColor)}>{results.wrong}</b> wrongs.
      {studyPrompts.length > 0 && (
        <> Here are problems to study for next time.</>
      )}
      {studyPrompts.length > 0 && (
        <ul style={levelUlStyle}>
          {studyResources.map((resource, idx) => (
            <li key={getKey('StudyList', idx)} style={levelLiStyle}>
              <Code>
                {resource.link ? (
                  <Link href={resource.link}>
                    <b>{studyPrompts[idx]}</b>
                  </Link>
                ) : (
                  <b>{studyPrompts[idx]}</b>
                )}
              </Code>{' '}
              is typed as <Code>{resource.combo}</Code>
            </li>
          ))}
        </ul>
      )}
      <div style={levelStyle}>
        <Button
          label="Play Again"
          onClick={handlePlayAgain}
          onTouchEnd={handlePlayAgain}
          style={buttonStyle}
        />
      </div>
    </FramedView>
  );
}

export default Ending;
