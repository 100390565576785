import React, { useState, useEffect } from 'react';

import { stopEventEffects } from '~/utils';
import Modal from '~/components/Modal';
import TextInput from '~/components/inputs/TextInput';
import COLORS from '~/constants/colors';
import { FONTS, FONT_WEIGHTS } from '~/constants/typography';
import Break from '~/components/flow/Break';
import Gap from '~/components/flow/Gap';

import { Sounds } from '../../../models';
import { MARKS, ERRORS } from '../../../constants/language';
import { MISSING } from '../../../constants/defaults';
import { getPlaySample } from '../../../helpers';

interface SearchProps {
  sounds?: Sounds;
}

function Search({ sounds }: SearchProps) {
  const [visible, setVisible] = useState(false);
  const [matches, setMatches] = useState<Sounds>();
  const getResultKey = (idx) => `PagesBeatbloxWriteSearchResult${idx}`;

  useEffect(() => {
    if (sounds) setMatches(sounds);
  }, [sounds]);

  // Handlers
  const showModal = (event) => {
    stopEventEffects(event);
    setVisible(true);
  };
  const searchSounds = (event) => {
    const query = event.target.value;
    let newMatches;

    stopEventEffects(event);
    if (!query) newMatches = sounds;
    else {
      newMatches = sounds
        ?.map((s) => {
          let score = 0;

          // TODO: improve match ranking method
          if (s.notation.includes(query))
            score += (4 * query.length) / s.notation.length;
          else if (s.notation.toLowerCase().includes(query.toLowerCase()))
            score += (3 * query.length) / s.notation.length;
          else if (s.name.includes(query))
            score += (2 * query.length) / s.name.length;
          else if (s.name.toLowerCase().includes(query.toLowerCase()))
            score += query.length / s.name.length;

          return [s, score];
        })
        .filter((pair) => pair[1])
        .sort((a, b) => Number(b[1]) - Number(a[1]))
        .map((pair) => pair[0]);
    }
    if (newMatches) setMatches(newMatches);
  };

  // Styling
  const modalStyle = {
    width: 'min(95vw, 30em)',
  };
  const inputStyle = {
    width: 'calc(100% - 1em)',
  };
  const loadingStyle = {
    marginBottom: '1em',
  };
  const resultStyle = {
    display: 'inline-block',
    margin: '0 0.1em 0.1em 0',
    padding: '0.3em',
    border: `thin solid ${COLORS.gray}`,
    borderRadius: '0.4em',
    color: COLORS.tundora,
    fontFamily: FONTS.typewriter,
    cursor: 'pointer',
  };
  const buttonStyle = {
    ...resultStyle,
    margin: '0.05em',
  };
  const errorStyle = {
    ...resultStyle,
    color: COLORS.white,
    border: `thin solid ${COLORS.mandy}`,
    backgroundColor: COLORS.mandy,
  };
  const notationStyle = {
    padding: '0 0.2em',
    color: COLORS.tundora,
    backgroundColor: COLORS.alto,
    borderRadius: '0.2em',
    fontWeight: FONT_WEIGHTS.bold,
  };
  const missingStyle = {
    ...notationStyle,
    backgroundColor: COLORS.cinderella,
  };

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onMouseUp={showModal}
        onTouchEnd={showModal}
        style={buttonStyle}
      >
        <span style={notationStyle}>B</span> sounds
      </div>
      <Modal
        visible={visible}
        title="Current Sounds"
        hider={() => setVisible(false)}
        style={modalStyle}
      >
        <TextInput
          disabled={!sounds}
          placeholder="Search"
          onChange={searchSounds}
          style={inputStyle}
        />
        <Break />
        <div>
          {!matches && (
            <>
              <span style={loadingStyle}>Loading, please wait...</span>
              <Gap />
            </>
          )}
          {matches &&
            matches.length > 0 &&
            matches.map((s, idx) => (
              <div
                key={getResultKey(idx)}
                role="button"
                tabIndex={-1}
                onMouseUp={getPlaySample(s.sample.recording.url)}
                onTouchEnd={getPlaySample(s.sample.recording.url)}
                style={resultStyle}
              >
                <span style={notationStyle}>{s.notation}</span> {s.name}
              </div>
            ))}
          {matches && matches.length === 0 && (
            <div
              role="button"
              tabIndex={-1}
              onMouseUp={getPlaySample(MISSING.sample.recording.url)}
              onTouchEnd={getPlaySample(MISSING.sample.recording.url)}
              style={errorStyle}
            >
              <span style={missingStyle}>{MARKS.missing}</span>{' '}
              {ERRORS.notation}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Search;
