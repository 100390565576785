import React, { ReactElement, PropsWithChildren, CSSProperties } from 'react';

import COLORS from '~/constants/colors';
import { FONT_SIZES } from '~/constants/typography';
import PATHS from '~/constants/paths';
import Link from '~/components/flow/Link';
import { getHostUrl } from '~/helpers/routing';

interface HollowStudyProps {
  action?: string;
  credits?: { name: string; link: string }[];
  style?: CSSProperties;
}

function HollowStudy({
  action = 'Powered by',
  credits,
  style,
  children,
}: PropsWithChildren<HollowStudyProps>): ReactElement {
  const fullCredits = [
    ...(credits ?? []),
    { name: 'Ponder', link: getHostUrl(PATHS.root) },
  ];
  const getKey = (idx) => `ComponentsBackgroundsHollowStudySpan${idx}`;

  // Styling
  const hollowStudyStyle = {
    display: 'table',
    width: '100%',
    height: '100vh',
    backgroundColor: COLORS.pickledBluewood,
    ...style,
  };
  const containerStyle = {
    display: 'table-row',
    height: 1,
    verticalAlign: 'bottom',
    fontSize: FONT_SIZES.subtext,
    textAlign: 'right' as const,
  };
  const creditStyle = {
    color: COLORS.cornflower,
    marginBottom: '1.5em',
    marginRight: '1.5em',
  };

  return (
    <div style={hollowStudyStyle} id="hollow-study">
      <div style={containerStyle} />
      {children}
      <div style={containerStyle}>
        <p style={creditStyle}>
          {action} <Link href={fullCredits[0].link}>{fullCredits[0].name}</Link>
          {fullCredits.slice(1).map((c, idx, { length }) =>
            idx < length - 1 ? (
              <span key={getKey(idx)}>
                , <Link href={c.link}>{c.name}</Link>
              </span>
            ) : (
              <span key={getKey(idx)}>
                {length > 1 ? ', ' : ' '}
                and <Link href={c.link}>{c.name}</Link>
              </span>
            )
          )}
        </p>
      </div>
    </div>
  );
}

export default HollowStudy;
