import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useEffect,
} from 'react';
import type YouTubePlayer from 'youtube-player';

import { shuffleArray } from '~/utils';
import COLORS from '~/constants/colors';
import { FONT_SIZES, FONT_WEIGHTS } from '~/constants/typography';
import VARIANTS from '~/constants/variants';
import Button from '~/components/buttons/Button';
import Modal from '~/components/Modal';
import Link from '~/components/flow/Link';
import Title from '~/components/titles/Title';

import Carousel, { useCarousel } from '../components/Carousel';
import { AnySettings, Problem, Song } from '../models';
import { DEFAULT_PLAY_SETTINGS } from '../constants/defaults';
import { SONGS, HOMEWORKS } from '../constants/alpha';
import { INDEX_MAP } from '../constants/kana';
import PHASES from '../constants/phases';
import { getVideoId, getWindowDims } from '../utils';
import SongNumberInput from '../components/SongNumberInput';
import SmallButton from '../components/SmallButton';
import OptionSelect from '../components/OptionSelect';
import Promo from '../components/Promo';
import Shuffle from '../components/Shuffle';
import InputModeFlipper from '../components/InputModeFlipper';
import PadLabelsFlipper from '../components/PadLabelsFlipper';
import GuidanceSwitch from '../components/GuidanceSwitch';
import StartButton from '../components/StartButton';
import NoteVelocitySlider from '../components/NoteVelocitySlider';
import { INPUT_MODES } from '../constants/layout';

interface MenuProps {
  phase: string;
  setPhase: Dispatch<SetStateAction<string>>;
  setPrevPhase: Dispatch<SetStateAction<string>>;
  setSettings: Dispatch<SetStateAction<AnySettings>>;
  setSong: Dispatch<SetStateAction<Song>>;
  videoPlayer: YouTubePlayer;
}

function Menu({
  phase,
  setPhase,
  setPrevPhase,
  setSettings,
  setSong,
  videoPlayer,
}: MenuProps): ReactElement {
  const songNumberRef = useRef<HTMLInputElement>(null);
  const [songIdx, setSongIdx, carouselHandlers, carouselContainerStyle] =
    useCarousel(SONGS.length, undefined, undefined, undefined, songNumberRef);
  const [songVolume] = useState(100);
  const [rhythmIdx, setRhythmIdx] = useState(0);
  const [homeworkIdx, setHomeworkIdx] = useState(0);
  const [randomize, setRandomize] = useState(false);
  const [velocity, setVelocity] = useState(DEFAULT_PLAY_SETTINGS.velocity);
  const song = SONGS[songIdx];
  const rhythm = song.rhythms[rhythmIdx];
  const homework = HOMEWORKS[homeworkIdx];
  const canAlign = rhythm?.timings.length > 0 && homework.problems.length > 0;
  const previewVideoId = getVideoId(song.preview ?? song.source);
  const previewLink = `https://www.youtube.com/embed/${previewVideoId}?fs=0&iv_load_policy=3&modestbranding=1&playsinline=1&enablejsapi=1`;
  const previewIFrameId = 'flowgo-menu-preview-iframe';
  const windowDims = getWindowDims();
  const getKey = (type, idx) => `PagesFlowGoMenu${type}Option${idx}`;

  // Input pad settings and check homework answers are subset of labels
  const inputPadLabels = Object.keys(INDEX_MAP);
  const checkInclusion = (idx) =>
    HOMEWORKS[idx].problems
      .map((problem) => problem.answers)
      .flat()
      .every((answer) => inputPadLabels.includes(answer));
  const answersIncluded = checkInclusion(homeworkIdx);
  const [inputMode, setInputMode] = useState(
    answersIncluded ? INPUT_MODES.jog : INPUT_MODES.key
  );
  const [whichPadLabels, setWhichPadLabels] = useState('hiragana');
  const [guidance, setGuidance] = useState(DEFAULT_PLAY_SETTINGS.guidance);

  // Interface relevant
  const [showPreview, setShowPreview] = useState(false);
  const [showRhythmShoutout, setShowRhythmShoutout] = useState(false);
  const [showHomeworkShoutout, setShowHomeworkShoutout] = useState(false);
  const disabled = !canAlign;
  const startButtonText = !disabled ? 'Flow GO!' : 'Flow Broken...';
  const previewWidth = windowDims.w - 0.7 * parseInt(FONT_SIZES.text, 10) * 3;

  // Handle menu interactions
  const handleSongChoice = (idx) => {
    setSongIdx(idx);
    setRhythmIdx(0);
  };
  const handleHomeworkChoice = (option) => {
    setHomeworkIdx(option.value);
    if (!checkInclusion(option.value)) setInputMode(INPUT_MODES.key);
  };
  const handleHidePreview = () => {
    const previewPlayer = document.getElementById(previewIFrameId);
    const msg = '{"event":"command","func":"pauseVideo","args":""}';

    (previewPlayer as HTMLIFrameElement)?.contentWindow?.postMessage(msg, '*');
    setShowPreview(false);
  };

  // Handle starting game
  const alignHomework = (hw, r, shuffle) => {
    const ps: Problem[] = [];
    let i = r.timings.length;
    let j = 0;

    while (i > 0) {
      const problemIdx = j % hw.problems.length;
      const problem = hw.problems[problemIdx];

      if (i < problem.answers.length) problem.answers.slice(0, i);
      ps.push(problem);
      j += 1;
      i -= problem.answers.length;
    }

    return { ...hw, problems: shuffle ? shuffleArray(ps) : ps };
  };
  const handleStart = () => {
    if (canAlign && videoPlayer) {
      const compare = (a, b) => a - b;
      const safeRhythm = { ...rhythm, timings: rhythm.timings.sort(compare) };
      const safeHomework = alignHomework(homework, safeRhythm, randomize);

      setSettings({
        song,
        rhythm: safeRhythm,
        homework: safeHomework,
        velocity,
        inputMode,
        whichPadLabels,
        guidance,
      });

      // Needed here for mobile not allowing autoplay
      videoPlayer.setVolume(songVolume);
      setShowPreview(false); // Remove iFrame render to reduce lag maybe
      videoPlayer.loadVideoById(getVideoId(song.source));
      setPrevPhase(PHASES.menu);
      setPhase(PHASES.play);
    }
  };

  // Styling
  const menuStyle = {
    display: phase === PHASES.menu ? 'block' : 'none',
  };
  const modalContainerStyle = {
    padding: '0em 0.5em',
  };
  const previewStyle = {
    borderRadius: '0.5em',
    marginBottom: '0.5em',
  };
  const previewModalStyle = {
    minWidth: `${Math.min(previewWidth, 480)}px`,
  };
  const slideByStyle = {
    fontWeight: FONT_WEIGHTS.thin,
  };
  const levelStyle = {
    display: 'table',
    margin: 'auto',
    marginTop: '0.25em',
  };
  const inputOptionLevelStyle = {
    ...levelStyle,
    marginTop: '0.38em',
  };
  const centerWingStyle = {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center' as const,
  };
  const songNumberCenterWingStyle = {
    ...centerWingStyle,
    display: 'inline-flex',
  };
  const rightWingStyle = {
    ...centerWingStyle,
    width: 'min(10em, 13vw)',
    textAlign: 'left' as const,
  };
  const songNumberRightWingStyle = {
    ...rightWingStyle,
    display: 'inline-flex',
    width: 'min(10em, 25vw)',
  };
  const leftWingStyle = {
    ...rightWingStyle,
    textAlign: 'right' as const,
  };
  const songNumberLeftWingStyle = {
    ...leftWingStyle,
    width: 'min(10em, 25vw)',
  };
  const levelRowSpaceStyle = {
    height: '0.15em',
  };
  const shufflePlaceholderStyle = {
    display: 'inline-block',
    width: '2em',
    margin: '0 0.25em',
    paddingRight: '0.15em',
  };
  const promoPlaceholderStyle = {
    display: 'inline-block',
    width: '1.5em',
    margin: '0 0.25em 0 0',
    padding: '0.3em 0.1em',
    fontSize: '1.25em',
  };

  // Handle updating get ready
  useEffect(() => {
    if (phase === PHASES.menu) setSong(song);
  }, [songIdx, videoPlayer, phase]);

  return (
    <div style={menuStyle}>
      <Carousel
        activeIdx={songIdx}
        setActiveIdx={handleSongChoice}
        handlers={carouselHandlers}
        containerStyle={carouselContainerStyle}
      >
        {SONGS.map((s, idx) => (
          <p key={getKey('CarouselSlide', idx)}>
            <i>{s.title}</i>
            <span style={slideByStyle}> by </span>
            <b>{s.artist}</b>
          </p>
        ))}
      </Carousel>
      <br />
      <div style={{ ...levelStyle, marginTop: '0.5em' }}>
        <div style={songNumberLeftWingStyle} />
        <div style={songNumberCenterWingStyle}>
          <SongNumberInput callback={handleSongChoice} ref={songNumberRef} />
        </div>
        <div style={songNumberRightWingStyle}>
          <SmallButton
            label="Preview"
            active={showPreview}
            callback={() => setShowPreview(true)}
          />
        </div>
      </div>
      <Title color={COLORS.white}>Rhythm</Title>
      <table style={levelStyle}>
        <tbody>
          <tr>
            <td style={leftWingStyle}>
              {rhythm?.promoText || rhythm?.promoLink ? (
                <Promo
                  label="🎉"
                  callback={() => setShowRhythmShoutout(true)}
                />
              ) : (
                <div style={promoPlaceholderStyle} />
              )}
            </td>
            <td style={centerWingStyle}>
              <OptionSelect
                placeholder={
                  rhythm ? 'Select a pattern...' : 'No patterns available'
                }
                value={{
                  value: rhythmIdx,
                  label: rhythm?.name,
                }}
                onChange={(option) => setRhythmIdx(option.value)}
                options={song.rhythms.map((r, idx) => ({
                  value: idx,
                  label: r.name,
                }))}
                isSearchable={false}
                controlShouldRenderValue={!!rhythm}
                disabled={!rhythm}
              />
            </td>
            <td style={rightWingStyle}>
              <div style={shufflePlaceholderStyle} />
            </td>
          </tr>
          <tr style={levelRowSpaceStyle} />
          <tr style={levelRowSpaceStyle} />
          <tr>
            <td style={leftWingStyle} />
            <td style={centerWingStyle}>
              <NoteVelocitySlider
                velocity={velocity}
                setVelocity={setVelocity}
              />
            </td>
            <td style={rightWingStyle} />
          </tr>
        </tbody>
      </table>
      <Title color={COLORS.white}>Homework</Title>
      <table style={levelStyle}>
        <tbody>
          <tr>
            <td style={leftWingStyle}>
              {homework?.promoText || homework?.promoLink ? (
                <Promo
                  label="💯"
                  callback={() => setShowHomeworkShoutout(true)}
                />
              ) : (
                <div style={promoPlaceholderStyle} />
              )}
            </td>
            <td style={centerWingStyle}>
              <OptionSelect
                placeholder="Select an assignment..."
                value={{
                  value: homeworkIdx,
                  label: homework.title,
                }}
                onChange={handleHomeworkChoice}
                options={HOMEWORKS.map((hw, idx) => ({
                  value: idx,
                  label: hw.title,
                }))}
                isSearchable={false}
              />
            </td>
            <td style={rightWingStyle}>
              <Shuffle checked={randomize} callback={setRandomize} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={inputOptionLevelStyle}>
        <InputModeFlipper
          mode={inputMode}
          setMode={setInputMode}
          answersIncluded={answersIncluded}
        />
        <PadLabelsFlipper
          which={whichPadLabels}
          setWhich={setWhichPadLabels}
          inputMode={inputMode}
        />
        <GuidanceSwitch
          label="Hints"
          guidance={guidance}
          setGuidance={setGuidance}
          inputMode={inputMode}
        />
      </div>
      <br />
      <StartButton
        label={startButtonText}
        disabled={disabled}
        callback={handleStart}
      />
      <Modal
        visible={showPreview}
        hider={handleHidePreview}
        style={previewModalStyle}
      >
        <iframe
          id={previewIFrameId}
          title={getKey('MenuPreview', 0)}
          width={Math.min(previewWidth, 480)}
          height={Math.min((previewWidth * 315) / 560, 270)}
          src={previewLink}
          frameBorder="0"
          allow="fullscreen; encrypted-media"
          style={previewStyle}
        />
        <br />
        <Button
          label="Noice"
          width="100%"
          variant={VARIANTS.warning}
          callback={handleHidePreview}
        />
      </Modal>
      <Modal
        visible={showRhythmShoutout}
        title="Rhythm Recorder's Shoutout!"
        hider={() => setShowRhythmShoutout(false)}
      >
        <div style={modalContainerStyle}>
          {rhythm?.promoLink ? (
            <Link href={rhythm?.promoLink}>
              <span>{rhythm?.promoText ?? rhythm?.promoLink}</span>
            </Link>
          ) : (
            <span>{rhythm?.promoText ?? rhythm?.promoLink}</span>
          )}
        </div>
        <br />
        <Button
          label="So True"
          width="100%"
          variant={VARIANTS.success}
          callback={() => setShowRhythmShoutout(false)}
        />
      </Modal>
      <Modal
        visible={showHomeworkShoutout}
        title="Homework Maker's Shoutout!"
        hider={() => setShowHomeworkShoutout(false)}
      >
        <div style={modalContainerStyle}>
          {homework?.promoLink ? (
            <Link href={homework?.promoLink}>
              <span>{homework?.promoText ?? homework?.promoLink}</span>
            </Link>
          ) : (
            <span>{homework?.promoText ?? homework?.promoLink}</span>
          )}
        </div>
        <br />
        <Button
          label="Poggers"
          width="100%"
          variant={VARIANTS.success}
          callback={() => setShowHomeworkShoutout(false)}
        />
      </Modal>
    </div>
  );
}

export default Menu;
