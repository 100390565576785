import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  CSSProperties,
  ReactElement,
  useRef,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
} from 'react';
import { isMobile } from 'react-device-detect';

import { mod } from '~/utils';
import { FONT_SIZES } from '~/constants/typography';
import COLORS from '~/constants/colors';

interface NumberInputProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  width?: string;
  callback?: (value: number) => void;
  listId?: string;
  options?: [number, string][];
  optionAriaLabel?: string;
  style?: CSSProperties;
  ref: ForwardedRef<HTMLInputElement>;
}

function NumberInput({
  width,
  callback,
  listId,
  options,
  optionAriaLabel,
  style,
  ref,
  ...props
}: NumberInputProps): ReactElement {
  const numberInputRef = (ref ??
    useRef<HTMLInputElement>(null)) as MutableRefObject<HTMLInputElement>;
  const showList = isMobile && listId && options;
  const getKey = (idx) => `ComponentsInputsNumberInputDataListOption${idx}`;

  // Event handlers
  let allowOnChange = true;
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();
    }
    allowOnChange = false;
  };
  const handleKeyUp = () => {
    allowOnChange = true;
  };
  const updateCurrentValue = (value) => {
    const getValue = (idx) =>
      options ? options[mod(idx, options.length)][0] : idx;
    const newValue = value && getValue(Number(value));

    if (value || value === 0) callback?.(value);
    if (numberInputRef.current) numberInputRef.current.value = newValue;
  };
  const handleBlur = (event) => {
    event.preventDefault();
    event.stopPropagation();
    updateCurrentValue(event.target.value);
  };
  const handleChange = (event) => {
    if (event.target.value && allowOnChange) handleBlur(event);
  };

  // Styling
  const numberInputStyle = {
    display: 'inline-block',
    width: width ?? '8em',
    fontSize: FONT_SIZES.text,
    borderRadius: '0.3em',
    borderStyle: 'solid',
    borderWidth: 'thin',
    padding: '0.4em 0.6em',
    outline: 'none',
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.alto,
    ...style,
  };
  // TODO: consider hiding default spinner arrow buttons and making custom ones that change value directly

  return (
    <>
      <input
        type="number"
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        {...(showList && { list: listId })}
        inputMode="numeric"
        style={numberInputStyle}
        ref={numberInputRef}
        {...props}
      />
      {showList && (
        <datalist id={listId}>
          {options.map((o, idx) => (
            <option key={getKey(idx)} aria-label={optionAriaLabel} value={o[0]}>
              {o[1]}
            </option>
          ))}
        </datalist>
      )}
    </>
  );
}

export default forwardRef(
  (props: NumberInputProps, ref: ForwardedRef<HTMLInputElement>) =>
    NumberInput({ ...props, ref })
);
